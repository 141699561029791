import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import {
  Card,
  Table,
  Button,
  Row,
  Col,
  Form,
  Badge,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const LeaveRequest = () => {
  const [cuti, setCuti] = useState([]);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [id_karyawan, setIdKaryawan] = useState(0);
  const [karyawan, setKaryawan] = useState([]);
  const [akses, setAkses] = useState(false);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Time Management")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    Axios.get("/karyawan/options")
      .then((response) => {
        setKaryawan(response.data.data);
      })
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });

    async function loadTable() {
      const user_permission = localStorage.getItem("user_permission");
      if (user_permission.includes("Time Management")) {
        setAkses(true);
      } else {
        setIdKaryawan(localStorage.getItem("id_user"));
      }
    }

    loadTable();
  }, []);

  useEffect(() => {
    async function loadTable() {
      $("#example").DataTable().destroy();
      await Axios.get(`/jurnal_cuti/cuti/${tahun}/${id_karyawan}`)
        .then((response) => {
          setCuti(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      if ($.fn.dataTable.isDataTable("#example")) {
        $(document).ready(function () {
          $("#example").DataTable();
        });
      } else {
        $(document).ready(function () {
          $("#example").DataTable({ ordering: false });
        });
      }
    }
    loadTable();
  }, [tahun, id_karyawan, counter]);

  function confirmDelete(id, status_cuti) {
    if (parseInt(status_cuti) === 0) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this record!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          Axios.delete(`/jurnal_cuti/${id}`).then((response) => {
            if (response.data.success) {
              swal("Delete Confirmation!", "Data deleted successfully", "success");
              setCounter(counter - 1);
            } else {
              swal("Delete Confirmation!", response.data.message, "error");
            }
          });
        } else {
          swal("Your record is safe!");
        }
      });
    } else {
      swal("Delete Confirmation!", "Cannot delete approved leave", "error");
    }
  }

  function updateStatus(id, status_cuti) {
    let status = 0;
    if (parseInt(status) === status_cuti) status = 1;

    if (parseInt(status) === 0) {
      Axios.post(`/jurnal_cuti/update_status?id=${id}&status_cuti=${status}`)
        .then((response) => {
          if (response.data.success) {
            setCounter(counter - 1);
          } else {
            console.log(response.data.message);
          }
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    } else {
      Axios.post(`/public/jurnal_cuti/approve/${id}`)
        .then((response) => {
          if (response.data.success) {
            setCounter(counter + 1);
          } else {
            swal("Error", response.data.message, "error");
            console.log(response.data.message);
          }
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Filter</Card.Header>
          <Card.Body>
            <Row>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={4} style={{ fontSize: 13 }}>
                    Year
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      required
                      type="number"
                      name="tahun"
                      size="sm"
                      defaultValue={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={4} style={{ fontSize: 13 }}>
                    Employee
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      size="sm"
                      name="id_karyawan"
                      value={id_karyawan}
                      onChange={(e) => setIdKaryawan(e.target.value)}
                      disabled={!akses}
                    >
                      {karyawan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Header as="h5">Leave Request</Card.Header>
          <Card.Body>
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              className="compact"
              style={{ minHeight: "210px" }}
              id="example"
            >
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Leave Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cuti.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.nama_karyawan}</td>
                    <td>{dt.nama_jenis}</td>
                    <td>{dt.tanggal_awal}</td>
                    <td>{dt.tanggal_akhir}</td>
                    <td>{dt.durasi} Day(s)</td>
                    <td style={{ textAlign: "center" }}>
                      {parseInt(dt.status_cuti) === 0 ? (
                        <Badge bg="secondary">Waiting</Badge>
                      ) : (
                        <Badge bg="success">Approved</Badge>
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DropdownButton as={ButtonGroup} variant="secondary" size="sm" title="">
                        <Dropdown.Item
                          onClick={() => window.open(`/leave_application_form/${dt.id}`, "_blank")}
                          style={{ fontSize: 14 }}
                        >
                          Form
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => updateStatus(dt.id, dt.status_cuti)}
                          style={{ fontSize: 14 }}
                        >
                          {parseInt(dt.status_cuti) === 0 ? "Approve" : "Cancel Approve"}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => confirmDelete(dt.id, dt.status_cuti)}
                          style={{ fontSize: 14 }}
                        >
                          Delete
                        </Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
              <Button variant="primary" size="sm" onClick={() => navigate("/apply_leave")}>
                Apply Leave
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default LeaveRequest;
