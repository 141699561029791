import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import validator from "validator";
import Axios from "../../myAxios";
import { mySqlDate } from "../../helper";

const KaryawanEdit = () => {
  const [nama_karyawan, setNamakaryawan] = useState("");
  const [email, setEmail] = useState("");
  const [tgl_lahir, setTglLahir] = useState(mySqlDate(new Date()));
  const [tgl_join, setTglJoin] = useState(mySqlDate(new Date()));
  const [tmp_lahir, setTmpLahir] = useState("");
  const [jns_kelamin, setJnsKelamin] = useState(0);
  const [no_ktp, setNoKTP] = useState("");
  const [no_hp, setNoHP] = useState("");
  const [alamat, setAlamat] = useState("");
  const [anggota_keluarga, setAnggotaKeluarga] = useState("");
  const [no_telp_keluarga, setNoTlpKeluarga] = useState("");
  const [hubungan_keluarga, setHubunganKeluarga] = useState("");
  const [pin, setPin] = useState("");
  const [board, setBoard] = useState(false);
  const [akses, setAkses] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function loadData() {
      await Axios.get(`/karyawan/${id}`)
        .then((response) => {
          let data = response.data.data;
          setNamakaryawan(data.nama_karyawan);
          setEmail(data.email);
          setTglLahir(data.tgl_lahir);
          setTglJoin(data.tgl_join);
          setTmpLahir(data.tmp_lahir);
          setJnsKelamin(data.jns_kelamin);
          setNoKTP(data.no_ktp);
          setNoHP(data.no_hp);
          setAlamat(data.alamat);
          setAnggotaKeluarga(data.anggota_keluarga);
          setNoTlpKeluarga(data.no_telp_keluarga);
          setHubunganKeluarga(data.hubungan_keluarga);
          setPin(data.pin);
          setBoard(parseInt(data.board) === 0 ? false : true);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      const user_permission = localStorage.getItem("user_permission");
      if (user_permission.includes("Time Management")) {
        setAkses(true);
      }
    }
    loadData();
  }, [id]);

  function Submit() {
    if (!validator.isEmpty(nama_karyawan) && !validator.isEmpty(email)) {
      const karyawan = {
        nama_karyawan: nama_karyawan,
        email: email,
        tgl_lahir: tgl_lahir,
        tgl_join: tgl_join,
        tmp_lahir: tmp_lahir,
        jns_kelamin: jns_kelamin,
        no_ktp: no_ktp,
        no_hp: no_hp,
        alamat: alamat,
        anggota_keluarga: anggota_keluarga,
        no_telp_keluarga: no_telp_keluarga,
        hubungan_keluarga: hubungan_keluarga,
        pin: pin,
        board: board ? 1 : 0,
      };

      Axios.put(`/karyawan/${id}`, karyawan)
        .then((response) => {
          if (response.data.success) {
            navigate(-1);
          } else {
            swal("Something went wrong", `An error occured while saving this data`, "error");
          }
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    } else {
      swal("Warning!", "Please fill in all the required fields!", "error");
    }
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-2">
          <Card.Header as="h5">Edit Employee</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Name</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="nama_karyawan"
                      value={nama_karyawan}
                      onChange={(e) => setNamakaryawan(e.target.value)}
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Check
                      type="switch"
                      label="Board"
                      onChange={(e) => setBoard(e.target.checked)}
                      checked={board}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>ID Card No</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="no_ktp"
                      value={no_ktp}
                      onChange={(e) => setNoKTP(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Place/Date of Birth</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="tmp_lahir"
                      value={tmp_lahir}
                      onChange={(e) => setTmpLahir(e.target.value)}
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tgl_lahir"
                      value={tgl_lahir}
                      onChange={(e) => setTglLahir(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Gender</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Check
                      inline
                      type="radio"
                      label="Male"
                      name="jns_kelamin"
                      value={0}
                      onChange={(e) => setJnsKelamin(e.target.value)}
                      checked={parseInt(jns_kelamin) === 0 ? true : false}
                      className="mt-1"
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Female"
                      name="jns_kelamin"
                      value={1}
                      onChange={(e) => setJnsKelamin(e.target.value)}
                      checked={parseInt(jns_kelamin) === 1 ? true : false}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Join Date</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tgl_join"
                      value={tgl_join}
                      onChange={(e) => setTglJoin(e.target.value)}
                      disabled={!akses}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Phone Number</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="no_hp"
                      value={no_hp}
                      onChange={(e) => setNoHP(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Email</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Address</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="alamat"
                      value={alamat}
                      onChange={(e) => setAlamat(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Name of Family Member</Form.Label>
                  </Col>
                  <Col sm="4" className="mt-1">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="anggota_keluarga"
                      value={anggota_keluarga}
                      onChange={(e) => setAnggotaKeluarga(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Family's Phone Number</Form.Label>
                  </Col>
                  <Col sm="4" className="mt-1">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="no_telp_keluarga"
                      value={no_telp_keluarga}
                      onChange={(e) => setNoTlpKeluarga(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Family Relationship</Form.Label>
                  </Col>
                  <Col sm="4" className="mt-1">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="hubungan_keluarga"
                      value={hubungan_keluarga}
                      onChange={(e) => setHubunganKeluarga(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>PIN (Fingerspot)</Form.Label>
                  </Col>
                  <Col sm="4" className="mt-1">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="pin"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                      disabled={!akses}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default KaryawanEdit;
