import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import LogoCom from "../../assets/company.png";
import TandaTangan from "../../assets/ttd.png";
import { Button, Row, Col } from "react-bootstrap";

const LeaveApplicationForm = () => {
  const [cuti, setCuti] = useState({});
  const [keterangan, setKeterangan] = useState("");
  const [counter, setCounter] = useState([0]);
  const { id } = useParams();

  useEffect(() => {
    async function loadTable() {
      await Axios.get(`/public/jurnal_cuti/${id}`)
        .then((response) => {
          let data = response.data.data;
          setCuti(data);
          setKeterangan(data.keterangan);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadTable();
  }, [id, counter]);

  function updateStatus(id, status_cuti) {
    let status = 0;
    if (status === parseInt(status_cuti)) status = 1;

    if (status === 0) {
      Axios.post(`/public/jurnal_cuti/update_status?id=${id}&status_cuti=${status}`)
        .then((response) => {
          if (response.data.success) {
            setCounter(counter + 1);
          } else {
            console.log(response.data.message);
          }
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    } else {
      Axios.post(`/public/jurnal_cuti/approve/${id}`)
        .then((response) => {
          if (response.data.success) {
            setCounter(counter + 1);
          } else {
            swal("Error", response.data.message, "error");
            console.log(response.data.message);
          }
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
  }

  return (
    <div id="page-content-wrapper">
      <div className="container-fluid ">
        <div style={{ padding: 10 }}>
          <Row>
            <Col md="6">
              <img src={LogoCom} height={48} alt="Logo" />
            </Col>
            <Col md="6">
              <div
                style={{ fontWeight: "bold", textAlign: "right", fontSize: 24, color: "#a3180e" }}
              >
                LEAVE APPLICATION FORM
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6">
              <div style={{ fontWeight: "bold" }}>EMPLOYEE : {cuti.nama_karyawan}</div>
            </Col>
            <Col md="6">
              <div style={{ fontWeight: "bold", textAlign: "right" }}>DATE : {cuti.dibuat}</div>
            </Col>
          </Row>

          <Row>
            <table width="100%" cellPadding="5">
              <thead>
                <tr>
                  <td
                    colSpan={4}
                    style={{ textAlign: "center", border: "1px solid black", fontWeight: "bold" }}
                  >
                    DESCRIPTION
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", fontWeight: "bold" }}>Date</td>
                  <td style={{ border: "1px solid black", fontWeight: "bold" }}>Time</td>
                  <td style={{ border: "1px solid black", fontWeight: "bold" }}>No. Days</td>
                  <td style={{ border: "1px solid black", fontWeight: "bold" }}>Note</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    {cuti.tanggal_awal + " - " + cuti.tanggal_akhir}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {cuti.jam_awal + " - " + cuti.jam_akhir}
                  </td>
                  <td style={{ border: "1px solid black" }}>{cuti.durasi}</td>
                  <td style={{ border: "1px solid black" }}>{cuti.note}</td>
                </tr>
                <tr>
                  <td colSpan={4} style={{ border: "1px solid black" }}>
                    <div style={{ fontWeight: "bold", marginTop: 10 }}>LEAVE TYPE :</div>{" "}
                    {cuti.nama_jenis}
                    <div style={{ fontWeight: "bold", marginTop: 10 }}>REASON : </div>
                    {keterangan.split("<br />").map((ket, id) => {
                      return <div key={id}>{ket}</div>;
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row>
            <div style={{ marginLeft: 20 }}>
              <div className="mt-5 mb-2">Bagus Putra Wijaya</div>
              {parseInt(cuti.status_cuti) === 1 ? (
                <img src={TandaTangan} height={48} alt="TTD" />
              ) : (
                <></>
              )}
            </div>
          </Row>
          <Row>
            <Col md="6">
              <div style={{ marginLeft: 20 }}>
                <div>Direktur</div>
              </div>
            </Col>
            <Col md="6">
              <div style={{ textAlign: "right" }}>{cuti.nama_karyawan}</div>
            </Col>
          </Row>
          <hr />
          <Row className="mt-3">
            <Col style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                size="sm"
                onClick={() => updateStatus(id, cuti.status_cuti)}
              >
                {parseInt(cuti.status_cuti) === 0 ? "Approve" : "Cancel Approve"}
              </Button>{" "}
              <Button
                variant="success"
                size="sm"
                onClick={() => window.open(`/leave_application_print/${id}`, "_blank")}
              >
                Print
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LeaveApplicationForm;
